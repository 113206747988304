<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('connect.deliver.addedittemplate')" class="connect">
    <CCol cols="12" lg="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="8" xl="8" class="pt-0 pb-0 text-left">
              <span>{{!deliverEmailTemplateIdExternal ? $t('common.New_mail_template') : $t('common.Edit_mail_template')}}</span>
            </CCol>
            <CCol cols="4" xl="4" class="pt-0 pb-0 text-right">
              <b-switch class="mb-0 mt-1 mt-xl-0" v-model="fullscreenMode" size="is-small" @input="toggleFullscreenMode()">{{ $t('connect.Fullscreen_email_editor') }}</b-switch>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <div v-if="loadingData">
            <CRow>
              <CCol cols="12" lg="12">
                <loadingSpinner mode="auto" :content="null"/>
              </CCol>
            </CRow>            
          </div>
          <div v-else>
            <CRow class="m-0">
              <CCol v-if="!fullscreenMode" cols="4" lg="4">
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0">
                    <CInput type="text" :label="$t('common.Name')" class="mb-0" v-model="template.name" required was-validated/>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0">
                    <CTextarea @input="countdown()" :maxlength="maxCount" :label="$t('common.Description')" rows="5" class="mb-0" v-model="template.description" required was-validated/>
                    <p class="countdown mt-1 mb-0" v-if="remainingCount > 0">{{remainingCount}} {{ $t('common.characters_remaining') }}</p>
                  </CCol>
                </CRow>                
              </CCol>
              <CCol :cols="editorCols.cols" :lg="editorCols.lg">
                <!-- <label>{{$t('common.Content_editor')}}</label> -->
                <EmailEditor ref="emailEditor"
                             :appearance="appearance"
                             :min-height="minHeight"
                             :project-id="projectId"
                             :locale="locale"
                             :tools="tools"
                             :options="options"/>
              </CCol>
            </CRow>
            <CRow class="m-0">
              <CCol cols="12" lg="12" class="pt-0">
                <div v-if="!template.deliver_email_template_id_external">
                  <CButton color="primary" @click="insertTemplate('draft');"><i class="fas fa-check mr-1"/>{{ $t('connect_deliver.Save_as_draft') }}</CButton>
                  <CButton color="primary" @click="insertTemplate('ready');"><i class="fas fa-save mr-1"/>{{ $t('Save') }}</CButton>
                  <CButton color="secondary" @click="resetTemplateData(); showDeliverOverview();"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
                </div>
                <div v-else>
                  <CButton color="primary" @click="updateTemplate('draft');"><i class="fas fa-check mr-1"/>{{ $t('connect_deliver.Save_as_draft') }}</CButton>
                  <CButton color="primary" @click="updateTemplate('ready');"><i class="fas fa-save mr-1"/>{{ $t('Save') }}</CButton>
                  <CButton color="secondary" @click="showDeliverOverview();"><i class="fas fa-times mr-1"/>{{ $t('cancel') }}</CButton>
                </div>                
              </CCol>                  
            </CRow>            
          </div>
        </CCardBody>
      </CCard>    
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';
import { EmailEditor } from 'vue-email-editor';

export default {
  name: 'Template',
  components: {
    loadingSpinner,
    noPermission,
    QuickEdit,
    Multiselect,    
    EmailEditor
  },
  watch: {
    $props: {
      handler() {
        if(this.$route.params.id !== undefined) this.deliverEmailTemplateIdExternal = this.$route.params.id;
        // Get the template details
        this.getTemplateDetails();        
      },
      deep: true,
      immediate: true,
    }
  },  
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,
      loadingData: false,
      deliverEmailTemplateIdExternal: null,
      fullscreenMode: false,
      editorCols: { cols: 8, lg: 8},
      template: {},
      maxCount: 1000,
      remainingCount: 1000,         
      emailEditorCols: null,
      emailCampaignCols: null,
      minHeight: '1000px',
      locale: 'en',
      projectId: 50935, //'50935' = Harry HR
      tools: {
        image: { enabled: true } // Disable image tool
      },
      options: {},
      appearance: {
        theme: 'light',
        panels: {
          tools: {
            dock: 'right',
          },
        },
      },
    };
  },
  methods: {
    toggleFullscreenMode() {
      (this.fullscreenMode) ? this.editorCols = { cols: 12, lg: 12 } : this.editorCols = { cols: 8, lg: 8 };
    },
    getTemplateDetails() {
      if(this.deliverEmailTemplateIdExternal !== null) {
        // Start the loader
        this.loadingData = true;
        // Get the template details        
        axios.get(process.env.VUE_APP_API_URL + '/v1/connect/deliver/template/' + this.deliverEmailTemplateIdExternal)
        .then(res => {
          this.template = res.data.data;
          // Trigger countdown function
          this.countdown();
          // Get the mailDesign of the campaign
          let mailDesign = JSON.parse(this.template.json);
          // Stop the loader
          this.loadingData = false;
          // Wait for 500ms and load the mailDesign into the editor
          setTimeout(function(){
            this.$refs.emailEditor.editor.loadDesign(mailDesign);
          }.bind(this), 500);
        })
        .catch(err => {
          console.error(err); 
        });
      } else {
        // Reset the data
        this.resetTemplateData();
      }
    },
    insertTemplate(campaignStatus) {
      let params = {};
      params.templateData = this.template;
      params.templateData.json = null;
      params.templateData.status = { status_tag: campaignStatus };
      
      if(this.$refs !== undefined && this.$refs.emailEditor !== undefined ) {
        this.$refs.emailEditor.editor.saveDesign((design) => {
          params.templateData.json = JSON.stringify(design);
        });
      }

      let name = params.templateData.name;
      let description = params.templateData.description;
      let status_tag = params.templateData.status.status_tag;
    
      // Set 500ms timeout to make sure that the JSON data is added to the templateData
      setTimeout(function(){
        // Check if the necessary params are available
        if(name && description && status_tag) {
          axios.post(process.env.VUE_APP_API_URL + '/v1/connect/deliver/template', params)
          .then(res => {
            // Show a success notice
            this.$buefy.toast.open({ message: this.$t('common.Template_added'), type: 'is-success', duration: 3000 });
            // Reset the data
            this.resetTemplateData();
            // Return to the campaign overview
            this.showDeliverOverview();
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })
        } else {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        }
      }.bind(this), 500);
    },
    updateTemplate(campaignStatus) {
      let params = {};
      params.templateData = this.template;
      params.templateData.json = null;
      params.templateData.status = { status_tag: campaignStatus };
      
      if(this.$refs !== undefined && this.$refs.emailEditor !== undefined ) {
        this.$refs.emailEditor.editor.saveDesign((design) => {
          params.templateData.json = JSON.stringify(design);
        });
      }

      let name = params.templateData.name;
      let description = params.templateData.description;
      let status_tag = params.templateData.status.status_tag;
    
      // Set 500ms timeout to make sure that the JSON data is added to the templateData
      setTimeout(function(){
        // Check if the necessary params are available
        if(name && description && status_tag) {
          axios.put(process.env.VUE_APP_API_URL + '/v1/connect/deliver/template/' + this.deliverEmailTemplateIdExternal, params)
          .then(res => {
            // Show a success notice
            this.$buefy.toast.open({ message: this.$t('common.Template_updated'), type: 'is-success', duration: 3000 });
            // Return to the campaign overview
            this.showDeliverOverview();
          })
          .catch(err => {
            console.error(err); 
            this.$buefy.toast.open({ message: this.$t('error_alert_text'), type: 'is-danger', duration: 2000 });
          })
        } else {
          this.$buefy.toast.open({ message: this.$t('common.mandatory_fields'), type: 'is-danger', duration: 2000 });
        }
      }.bind(this), 500);
    },
    showDeliverOverview() {
      // Set tab to activate values in localStorage
      localStorage.setItem('deliverTabToActivate', 'mail')
      localStorage.setItem('mailTabToActivate', 'templates');
      // Return to the deliver overview
      this.$router.push({path: '/connect/deliver'});
    },   
    resetTemplateData() {
      this.template = {
        deliver_email_template_id_external: null,
        name: null,
        description: null,
        status: {
          label: "Draft",
          status_tag: "draft"          
        },
      }
    },
    countdown() {
      if(this.template.description) this.remainingCount = this.maxCount - this.template.description.length;
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }       
  },
  mounted: function() {
    this.getPlatformPermissions();
  }
};
</script>

<style>
#bar {
  flex: 1;
  background-color: #40B883;
  color: #FFF;
  padding: 10px;
  display: flex;
}

#bar h1 {
  flex: 1;
  font-size: 16px;
  text-align: left;
}

#bar button {
  flex: 1;
  padding: 10px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: bold;
  background-color: #000;
  color: #FFF;
  border: 0px;
  max-width: 150px;
  cursor: pointer;
}

.unlayer-editor iframe {
  min-height: 1000px !important;
}

.unlayer-editor iframe .blockbuilder-branding {
  display: none;
}
</style>